<script>
  export let title, sorter, status;
</script>

<th class="clickable" on:click={sorter}>
  {title}
  <i 
    class="arrow {status != undefined ? 'active' : ''}" 
    class:down={status} 
    class:up={!status}>&#9660;
  </i>
</th>

<style>
  .clickable{ cursor: pointer; }
  .arrow{ position: absolute; transform: rotate(270deg); transition: .3s all ease; top: calc(50% - 10px); right: .2rem; }
  .active.down{ transition: .3s all ease; transform: rotate(0deg); }
  .active.up{ transition: .3s all ease; transform: rotate(180deg); }
  th{ text-align: center; vertical-align: middle; border: 1px solid rgb(214, 207, 207); padding: .3rem 1rem !important; position: relative; }
</style>