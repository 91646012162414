<script>
  import MPHelper from "../lib/MPHelper";
  import MPNumeric from "../lib/MPNumeric";

  export let updatePlan; // Function
  export let plan, selectedGrupo, grupos;

  function updateInput(event, plan) {
    const formattedValue = event.target.value.replace(",", ".");

    plan.preco = parseFloat(formattedValue);

    updatePlan(event, plan);
  };
</script>

<tr class="table-primary" class:focus={false} id="plan_{plan.prod_id}_tr">
  <!-- Proponent -->
  <td
    style="background-color: {MPHelper.colorRow(plan.proponente)};"
  >
    {MPHelper.shortProponente(plan.proponente)}
  </td>

  <!-- Seguradora -->
  <td class="stay-short" title="{plan.seguradora}">{plan.seguradora}</td>

  <!-- Câmbio -->
  <td>
    {#if plan.cambio_fornecedor != undefined && !isNaN(plan.cambio_fornecedor)}
      {plan.cambio_fornecedor}
    {:else}
      -
    {/if}
  </td>

  <!-- Destino -->
  <td>
    {#if selectedGrupo != 0}
      {grupos.find((gp) => gp[0] == selectedGrupo)[1]}
    {:else}
      -
    {/if}
  </td>

  <!-- Plano -->
  <td>
    {#if plan.proponente == "Real Seguro Viagem"}
      <a href="/produtos/{plan.prod_id}/edit" target="_blank" rel="noreferrer">
        {plan.plano}
      </a>
    {:else}
      {plan.plano}
    {/if}
  </td>

  <!--Data de pesquisa-->
  <td>
    {#if plan.data_da_pesquisa}
      {new Date(plan.data_da_pesquisa).toLocaleDateString("pt-BR", {day: '2-digit', month: '2-digit', year: 'numeric'})}
    {:else}
      -
    {/if}
  </td>
  <!-- Idade máxima -->
  <td>{plan.idade_max}</td>

  <!-- Assistência médica -->
  <td>{plan.assistencia_medica}</td>

  <!-- Covid -->
  <td>{plan.covid}</td>

  <!-- Preço -->
  {#if plan.proponente == "Real Seguro Viagem"}
    <td class="td-input-group">
      <div class="input-group-row">
        <input
          id="plan_{plan.prod_id}"
          name="plan_{plan.prod_id}"
          class="form-control"
          style="background-color: {plan.status}"
          value={MPNumeric.formatNumber(parseFloat(plan.preco))}
          on:change={(e) => updateInput(e, plan)}
        />
  
        <a
          href="/preco_lote?prod_id={plan.prod_id}"
          title="Preço em Lote"
          class="link__preco_lote"
          target="_blank"
          rel="noreferrer">$</a
        >
      </div>
      {#if plan.old_preco}
        <span title="Preço anterior" class="old_preco">
          Preço anterior: <b>{MPNumeric.formatNumber(parseFloat(plan.old_preco))}</b>
        </span>
      {/if}
    </td>
  {:else}
    <td>{MPNumeric.formatNumber(parseFloat(plan.preco))}</td>
  {/if}

  <!-- Preço por Dia -->
  <td>{MPNumeric.formatNumber(parseFloat(plan.p_d))}</td>

  <!-- Custo (em Reais) -->
  <td>{MPNumeric.formatNumber(parseFloat(plan.custo_em_reais))}</td>

  <!-- Custo (em Reais - por dia) -->
  <td>{MPNumeric.formatNumber(parseFloat(plan.c_reais_d))}</td>

  <!-- Custo (em Dollar) -->
  <td>{MPNumeric.formatNumber(parseFloat(plan.custo_em_dolar))}</td>

  <!-- Custo (em Dollar - por dia) -->
  <td>{MPNumeric.formatNumber(parseFloat(plan.c_dolar_d))}</td>

  <!-- Margem -->
  <td class:cheap={plan.margem < 45}>{plan.margem} %</td>
</tr>

<style>
  input { width: 100%; border-radius: 3px; text-align: center; background-color: white; padding: 0; }
  td { border: 1px solid #e5e7eb; box-sizing: content-box; padding: 6px 12px; text-align: center; font-size: 13px; }
  tr { transition: all ease 0.3s; }
  tr.table-primary.focus, tr.table-primary:hover { background-color: #efefff; }
  a { padding: unset; background-color: unset; background-image: unset; }
  .stay-short { max-width: 200px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; margin: 0; }
  .cheap { background-color: lightsalmon; }
  .td-input-group { padding: 10px; min-width: 255px; border-top: 0; border-right: 0; border-left: 0; border-bottom: 1px solid #e5e7eb; height: 100%; }
  .input-group-row { display: flex; align-items: center; justify-content: center; width: 100%; background: transparent; padding: 0; }
  .link__preco_lote { display: block; outline: none; color: white; background-color: #35c335; text-decoration: none; transition: all ease 0.3s; border-radius: 0.25rem; padding: 0.3rem 0.75rem !important; margin: 0 0 0 0.25rem; font-weight: bold; }
  .old_preco { width: 100%; display: block; background: transparent; padding: 0; font-style: italic; color: #ff8400; margin-top: .25rem; }
</style>
