<script>
  export let seguradoraSelected, seguradoras, callback;
</script>

<div class="col-auto mt-4">
  <label for="filterByInsuranceCompany">Filtrar por Seguradoras:</label>
  <br/>
  <!-- svelte-ignore a11y-no-onchange -->
  <select bind:value={seguradoraSelected} on:change={callback} name="filterByInsuranceCompany" id="filterByInsuranceCompany" class="form-control" >
    <option>-- SELECIONAR --</option>
    {#each seguradoras as seguradora}
      <option value={seguradora}>
        {seguradora}
      </option>
    {/each}
  </select>
</div>