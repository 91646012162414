/* eslint no-console: 0 */
// Run this example by adding <%= vite_javascript_tag 'hello_svelte' %> (and
// <%= vite_stylesheet_tag 'hello_svelte' %> if you have styles in your component)
// to the head of your layout file,
// like app/views/layouts/application.html.erb.
// All it does is render <div>Hello Svelte!</div> at the bottom of the page.
// VERSION = 0.0.2

import MovePreco from '../src/move-preco/MovePreco.svelte'

document.addEventListener('DOMContentLoaded', () => {
  const movePrecoContainer = document.getElementById('move-preco');

  if(!movePrecoContainer) return;

  const crfToken = document.querySelector('meta[name="csrf-token"]').content;

  window.app = new MovePreco({
    target: movePrecoContainer,
    props: { authenticityToken: crfToken }
  });
});

