<script>
  export let callback, empresas;
</script>

<div class="col-auto mt-4">
  <label for="RSV">Filtrar por Proponente:</label>
  <br>
  {#each Object.keys(empresas) as empresa}
    <div style="display: inline">
      <input type="checkbox" name="{empresa}" id="{empresa}" bind:checked={empresas[empresa]} on:change={callback}> 
      {empresa}
    </div>
  {/each} 
</div>