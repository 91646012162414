<script>
  export let focar, grupo_preco;
</script>

<div class="col-auto">
  <label for="actions">Opções:</label>
  <br>
  <div style="display: inline-block; margin-right: .5rem; margin-top: .5rem;">
    <input type="checkbox" id="focus-element" name="focus-element" bind:checked={focar}>
    <label class="normal-label" for="focus-element">
      Seguir Alterações
    </label>
  </div>
  <div style="display: inline-block; margin-right: .5rem; margin-top: .5rem;">
    <input type="checkbox" id="toggle-group-view" name="toggle-group-view" bind:checked={grupo_preco}>
    <label class="normal-label" for="toggle-group-view">
      Visualização de Grupo
    </label>
  </div>
</div>

<style>
  .normal-label { font-weight: normal; color: #495957; }
</style>