import MPNumeric from "./MPNumeric";
import { groupsByRangeMock } from "../utils/mocks";

export default class MPBuilder {
  static buildPlans = (plans, days) => {
    plans.forEach((plan) => {
      plan.preco = parseFloat(plan.preco);
      plan.custo_em_reais = parseFloat(plan.custo_em_reais || (plan.preco/2));
      plan.cambio_fornecedor = parseFloat(plan.cambio_fornecedor);
      plan.custo_em_dolar = parseFloat(plan.custo_em_dolar || (plan.cambio_fornecedor > 0 ? (plan.custo_em_reais / plan.cambio_fornecedor) : 0))
      plan.covid = (plan.covid || "").trim();
      plan.margem = MPNumeric.recalculateMargin(plan);
      plan.p_d = (plan.preco/days).toFixed(2);
      plan.c_reais_d = (plan.custo_em_reais/days).toFixed(2);
      plan.c_dolar_d = (plan.custo_em_dolar/days).toFixed(2);
      plan.status = "white";
    });
  
    return plans;
  };

  static buildRange = (planos) => {
    const range = structuredClone(groupsByRangeMock);
  
    planos.forEach(plan => {
      plan.assistencia_medica = plan.assistencia_medica && plan.assistencia_medica !== '-' ? plan.assistencia_medica : "0";
      
      if (typeof(plan.assistencia_medica == "float")) plan.assistencia_medica = plan.assistencia_medica.toString();
  
      const healthCare = parseFloat(plan.assistencia_medica.match(/(\d+)| /g).filter(el => el != " ")[0]);
  
      if(healthCare <   20000)                         range.lessThan20.plans.push(plan);
      if(healthCare >   20000 && healthCare <= 30000)  range.lessThan30.plans.push(plan);
      if(healthCare <=  40000 && healthCare >  30000)  range.lessThan40.plans.push(plan);
      if(healthCare <=  60000 && healthCare >  40000)  range.lessThan60.plans.push(plan);
      if(healthCare <= 150000 && healthCare >  60000)  range.lessThan150.plans.push(plan);
      if(healthCare >  150000)                         range.greaterThan150.plans.push(plan);
    }); 
  
    return range;
  };
};