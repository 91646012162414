<script>
  import { onMount } from "svelte";
  import MPOrganizer from "../lib/MPOrganizer";
  import MPNumeric from "../lib/MPNumeric";
  import MPHelper from "../lib/MPHelper";
  import MPRequests from "../lib/MPRequests";
  import { methodSimilarPlans } from "../utils/improveModal.js";

  export let modalStatus = false;
  export let selectedGrupo, grupos, dias, planos, updatePlan;

  const MARGEM_MINIMA_MELHORAR = 45;
  const MARGEM_MINIMA_PADRAO = 50;

  let container;
  let selectedDestinoName = "destino";
  let plansToUpgrade = [];

  onMount(() => {
    checkSimiliarPlans();
    const selectedGroupObject = grupos.find(group => group[0] === selectedGrupo);
    if (selectedGroupObject) selectedDestinoName = selectedGroupObject[1];
  })

  function toggleModal(){
    modalStatus = !modalStatus;
  }

  function checkSimiliarPlans(){
    if ($methodSimilarPlans == 1) firstStepImprovePrices();
    if ($methodSimilarPlans == 2) secondStepImprovePrices();
  }

  function improvePrices(){
    plansToUpgrade.forEach((plano) => {
      updatePlan(null, plano);
    })
    toggleModal();
  }

  function handleKeyPressOnImprove(e){
    if(e.key === "Enter") improvePrices();
  }

  function definePreviousValues(plano){
    plano.previousPrice = plano.preco;
    plano.previousMargin = plano.margem;
    plano.previousPricePerDay = plano.p_d;
    return plano;
  }

  function firstStepImprovePrices(){
    let planosConcorrentes = MPOrganizer.ordering(planos.filter((plano) => plano.proponente != "Real Seguro Viagem"));  
    if (new Set(planosConcorrentes.map((plano) => plano.proponente)).size < 2){
      MPRequests.fetchProponentWithoutPlans(1,dias, MPHelper.groupForDestination(selectedGrupo));
      return;
    }
    planosConcorrentes.forEach((plano) => (plano.position = null));
    let countPosition = 0
    planosConcorrentes.forEach((plano) => {
      if (plano.position !== null) return;
      plano.position = countPosition;
      planosConcorrentes.forEach((planoConcorrente) => {
        if (planoConcorrente.position == null && MPOrganizer.isSimilar(plano, planoConcorrente)) {
          planoConcorrente.position = plano.position;
        }
      });
      countPosition++;
    });

    planosConcorrentes = planosConcorrentes.sort((a, b) => a.position - b.position); 
    planosConcorrentes = MPOrganizer.removeDuplicatesPositions(planosConcorrentes);

    //Para cada posição pegar o produto da real de menor custo e diminuir de 4 a 10 centavos do concorrente de menor valor da posição
    let planosReal = MPOrganizer.copyByProponent(planos, "Real Seguro Viagem").sort((a, b) => a.custo_em_reais - b.custo_em_reais);
    console.log("Todos os planos da real: ",planosReal)
    planosConcorrentes.forEach((planoConcorrente) => {
      console.log("Planos da real da iteração atual do ajuste de preço: ",planosReal)
      if (planosReal.length == 0) return
      //Se 2 ou mais produtos da real forem iguais (medica + seguradora + idade + custo) eles devem ser alterados juntos e com o mesmo valor.
      let planosEqual = planosReal.filter((plano) => MPOrganizer.isEqual(plano, planosReal[0]));
      console.log("Plano da real[0] e lista de planos iguais:", planosReal[0], planosEqual)
      let range = Math.floor(Math.random() * 7 + 4) / 100
      //range de -4 a -10 centavos
      planosEqual.forEach((plano) => {
        let oldPrice = plano.preco;
        plano = definePreviousValues(plano);
        plano.preco = planoConcorrente.preco - range;
        plano.margem = MPNumeric.recalculateMargin(plano);
        plano.p_d = (plano.preco / dias).toFixed(2);
        if (plano.margem < MARGEM_MINIMA_MELHORAR){
          plano.margem = MARGEM_MINIMA_MELHORAR;
          plano.preco = MPNumeric.recalculatePrice(plano);
          plano.p_d = (plano.preco / dias).toFixed(2); 
        } 
        if(oldPrice != plano.preco) plansToUpgrade = [...plansToUpgrade, plano];
      });
      //remove os planos de igualdade da lista de planos da real
      planosEqual.forEach((plano) => {
        planosReal = planosReal.filter((planoReal) => planoReal.id != plano.id);
      });
    });
    //Se a real tiver mais produtos que a lista de posições os produtos que não foram usados para alteração no preço devem ter a margem setada para 50%   
    planosReal.forEach((plano) => {
      let oldPrice = plano.preco;
      plano = definePreviousValues(plano);
      plano.margem = MARGEM_MINIMA_PADRAO;
      plano.preco = MPNumeric.recalculatePrice(plano);
      plano.p_d = (plano.preco / dias).toFixed(2);
      if(oldPrice != plano.preco) plansToUpgrade = [...plansToUpgrade, plano];
    });
  }

  function secondStepImprovePrices(){
    let planosConcorrentes = planos.filter(plan => plan.proponente != "Real Seguro Viagem").sort((a, b) => a.custo_em_reais - b.custo_em_reais);
    let planosReal = MPOrganizer.copyByProponent(planos, "Real Seguro Viagem").sort((a, b) => a.custo_em_reais - b.custo_em_reais);
    let changedPlans = [];
    if (new Set(planosConcorrentes.map((plano) => plano.proponente)).size < 2){
      MPRequests.fetchProponentWithoutPlans(2,dias, MPHelper.groupForDestination(selectedGrupo));
      return;
    }
    planosConcorrentes.forEach((planoConcorrente) => {
      let change = false;
      let hasACheaperPlan = planosReal.find(planR => planR.preco < planoConcorrente.preco && MPOrganizer.isEqualOrBiggest(planR, planoConcorrente));
      if(hasACheaperPlan) return;

      let planosRealFiltered = planosReal.filter((plano) => 
        MPOrganizer.isEqualOrBiggest(plano, planoConcorrente) && 
        plano.preco >= planoConcorrente.preco &&
        !changedPlans.includes(plano.id)
      );
      
      planosRealFiltered.forEach((planoReal) => {
        if (change) return;
        
        const oldPrice = planoReal.preco;
        const range = Math.floor(Math.random() * 7 + 4) / 100
        
        planoReal = definePreviousValues(planoReal);
        planoReal.preco = planoConcorrente.preco - range;
        planoReal.margem = MPNumeric.recalculateMargin(planoReal);
        planoReal.p_d = (planoReal.preco / dias).toFixed(2);
        
        if (planoReal.margem < MARGEM_MINIMA_MELHORAR){
          planoReal.margem = MARGEM_MINIMA_MELHORAR;
          planoReal.preco = MPNumeric.recalculatePrice(planoReal);
          planoReal.p_d = (planoReal.preco / dias).toFixed(2); 
        }
        
        if(oldPrice != planoReal.preco) {
          plansToUpgrade = [...plansToUpgrade, planoReal];
          changedPlans.push(planoReal.id);
          change = true;
        }
      });
    });
  }
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<div bind:this={container} on:click={(e) => {if(e.target === container) toggleModal()}} class="modal">
  <div class="modal-content">
    <div class="modal-headers">
      <h2 class="modal-header-title">📊 Inteligência de Preços</h2>
      <button title="minimizar" class="close-btn" on:click={() => toggleModal()}></button>
    </div>
    <p>Foram identificadas as seguintes alterações de preço como vantajosas para nosso sistema em comparação aos preços de nossos concorrentes, levando em consideração o destino <strong>{selectedDestinoName}</strong> e a duração de <strong>{dias}</strong> dias. <strong>Você confirma tais modificações?</strong></p>

    {#if plansToUpgrade.length > 0}
      <div class="table-container">
        <table class="table mt-2">
          <thead class="sticky-thead">
            <tr>
              <th>Plano</th>
              <th>Preço Antigo</th>
              <th>Preço Novo</th>
              <th>Margem Antiga</th>
              <th>Margem Nova</th>
              <th>Preço por Dia Antigo</th>
              <th>Preço por Dia Novo</th>
            </tr>
          </thead>
          <tbody>
            {#each plansToUpgrade as plan}
              <tr>
                <td><a target="_blank" rel="noreferrer" href="https://app.seguroviagem.srv.br/produtos/{plan.prod_id}">{plan.plano}</a></td>
                <td class="text-danger">{plan.previousPrice}</td>
                <td class="text-success">{plan.preco}</td>
                <td class="text-danger">{plan.previousMargin}</td>
                <td class="text-success">{plan.margem}</td>
                <td class="text-danger">{plan.previousPricePerDay}</td>
                <td class="text-success">{plan.p_d}</td>
              </tr>
            {/each}
          </tbody>
        </table>
      </div>
      <button on:keypress={(e) => handleKeyPressOnImprove} on:click={improvePrices} class="btn btn-primary btn-sm py-2">Sim, alterar</button>
    {:else}
      <div class="d-flex justify-content-center align-items-center" style="height: 100%;">
        <p class="title">Não há planos para serem melhorados.</p>
      </div>
    {/if}
  </div>
</div>


<style>
  .sticky-thead{
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
  }
  .table-container{
    max-height: 300px;
    overflow-y: auto;
  }
  .modal-headers{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
  }
  .title{
    font-weight: bold;
    color: #8628FD;
    font-size: 2rem;
    text-align: center;
  }
  .modal-header-title{
    font-weight: bold;
    color: #8628FD;
    font-size: 1.5rem;
    margin: 0;
  }
  .modal {
    display: flex;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
  }
  .modal-content {
    background-color: #fefefe;
    margin: 12% auto;
    padding: 20px;
    width: 80%;
    height: fit-content;
  }
  .close-btn{
    background-color: #8628FD;
    color: white;
    padding: 3px 5px;
    border: none;
    border-radius: 0.2rem;
    cursor: pointer;
    width: 1%;
  }
  .close-btn:hover {
    opacity: 0.8;
  }
</style>