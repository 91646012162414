export default class MPNumeric {
  static formatNumber(number){
    if(typeof(number) !== 'number') return '-';
    
    return number.toFixed(2).replace('.', ',');
  };
  
  static formatStringToFloat(str){
    str = String(str).replace(',', '.');
    if(str == undefined || str == "" || str == null) return 0;

    return parseFloat(str.match(/(\d+)| /g)?.filter(el => el != " ")[0]);
  };
  
  static recalculateMargin(plan){
    const profitMargin = ((plan.preco - plan.custo_em_reais) / plan.preco);
    
    return Math.floor(profitMargin * 10000)/100;
  };

  static recalculatePrice(plan){
    let newPrice = (plan.custo_em_reais / (1 - (plan.margem/100) ));

    return parseFloat((newPrice + 0.01).toFixed(2));
  };
};