<script>
  import FiltroNome from './filters/FiltroNome.svelte';
  import FiltroCovid from './filters/FiltroCovid.svelte';
  import FiltroSeguradora from './filters/FiltroSeguradora.svelte';
  import FiltroIdade from './filters/FiltroIdade.svelte';
  import FiltroEmpresa from './filters/FiltroEmpresa.svelte';

  import MPOrganizer from '../lib/MPOrganizer';
  import MPBuilder from "../lib/MPBuilder";

  export let allPlans, planos, seguradoras, groupsByRange;

  let filters = { nomePlan: '', age: 0, covid: undefined }
  let seguradoraSelected, empresas = { "RSV": true,  "SP": true, "AV": true }

  const resetFilters = () => {
    filters.covid = filters.covid;
    filters.age = filters.age;
    seguradoraSelected = "-- SELECIONAR --";
  };

  const filterPlans = () =>{
    document.body.style.cursor='wait';

    planos = MPOrganizer.genericFilter([...allPlans], {
      nomePlan: filters.nomePlan,
      age: filters.age,
      covid: filters.covid,
      seguradora: seguradoraSelected,
      empresas: empresas
    });
    groupsByRange = MPBuilder.buildRange(planos);

    document.body.style.cursor='default';
    return planos;
  };

  $: { allPlans; resetFilters(); filterPlans() };
</script>

{#if allPlans.length > 0}
  <div class="row g-3 align-items-center">
    <FiltroNome bind:name={filters.nomePlan} callback={filterPlans} />
    <FiltroCovid bind:statusCovid={filters.covid} callback={filterPlans} />
    <FiltroSeguradora bind:seguradoraSelected {seguradoras} callback={filterPlans} />
    <FiltroIdade bind:age={filters.age} callback={filterPlans} />
    <FiltroEmpresa bind:empresas callback={filterPlans} />
  </div>
{/if}