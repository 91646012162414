<script>
  import { onMount } from "svelte";
  import { OrderStatus } from "./utils/orderStatus"

  // ---- Components
  import TableGroup from "./components/TableGroup.svelte";
  import Thead from "./components/Thead.svelte";
  import Actions from './components/Actions.svelte';
  import Loading from "./components/Loading.svelte";
  import TrTable from "./components/TrTable.svelte";
  import Filters from "./components/Filters.svelte";
  import MpHeader from "./components/MpHeader.svelte";
  import ChangeDays from "./components/ChangeDays.svelte";
  import SelectDestinos from "./components/SelectDestinos.svelte";
  import FloatingButtons from "./components/FloatingButtons.svelte";
  import ToastMessage from "./components/ToastMessage.svelte";
  import AutoImproveModal from "./components/AutoImproveModal.svelte";

  // ---- Stores
  import { methodSimilarPlans } from "./utils/improveModal.js";

  // ---- Mocks
  import { groupsByRangeMock } from "./utils/mocks.js";

  // ---- Helping Entities
  import MPHelper from "./lib/MPHelper";
  import MPNumeric from "./lib/MPNumeric";
  import MPBuilder from "./lib/MPBuilder";
  import MPRequests from "./lib/MPRequests";
  import MPOrganizer from "./lib/MPOrganizer";

  // ---- Token
  export let authenticityToken = "";

  // ---- Constantes
  const MARGEM_MINIMA = 35;

  // ---- Variables
  let dias = 1;
  let planos = [];
  let allPlans = [];
  let focar = false;
  let planosReal = [];
  let planosPrice = [];
  let inLoading = false;
  let oldPlanosPrice = [];
  let grupo_preco = false;
  let cambioFornecedores = [];
  let groupsByRange = groupsByRangeMock;
  let showMessage = false;
  let modalImproveStatus = false;
  let toastComponent;
  
  // ---- Filter Controller
  let selectedGrupo;
  let seguradoras = [];
  let reordering = true;
  let valorSelecionado = 0;
  let currentFilterType = "preco";
  let orderStatus = new OrderStatus();
  let grupos = [[0, "-- SELECIONAR --"]];

  // ---- Functions
  async function loadPlans() {
    loading();

    planosReal = planosPrice = planos = allPlans = [];

    if (selectedGrupo === 0) return loading();

    [planosReal, planosPrice] = await Promise.all([
      MPRequests.fetchRealPlans(selectedGrupo, dias),
      MPRequests.fetchPricePlans(MPHelper.groupForDestination(selectedGrupo), dias),
    ]);

    planos = planosReal.concat(planosPrice);
    allPlans = [...planos];
    seguradoras = [];

    planos.forEach((plan) => {
      if (isNaN(plan.cambio_fornecedor)) {
        const cambioFornecedor = cambioFornecedores.find(
          (cambio) => cambio.name == plan.seguradora,
        );

        if (cambioFornecedor) {
          plan.cambio_fornecedor = cambioFornecedor.cambio;
        } else {
          const cambio = MPRequests.fetchCambioFornecedor(plan.seguradora);

          cambioFornecedores.push({ name: plan.seguradora, cambio: cambio });

          plan.cambio_fornecedor = cambio;
        }
      };
      if (plan.seguradora.toLowerCase() == "travelace" || plan.seguradora.toLowerCase() == "universalassistance") plan.seguradora = "Universalassist";
      if (plan.seguradora.toLowerCase() == "welcomeassistance") plan.seguradora = "Welcome";
      if (!seguradoras.includes(plan.seguradora)) seguradoras.push(plan.seguradora);
    });

    planos = MPBuilder.buildPlans(planos, dias);

    if (planos.length > 0) groupsByRange = MPBuilder.buildRange(planos);
    oldPlanosPrice = planos.map((plano) => ({
      id: plano.id,
      preco: plano.preco,
    }));

    seguradoras = [...seguradoras.sort()];

    forceReordering();
    loading();
  };

  function showImproveModal(method){
    modalImproveStatus = !modalImproveStatus;
    $methodSimilarPlans = method;
  }

  // function improvePrices() {
  //   firstStepImprovePrices();
  //   secondStepImprovePrices();
  // }

  function updatePlan(event, plano) {
    planos[planos.findIndex((p) => p.id == plano.id)] = plano;
    plano.old_preco = oldPlanosPrice.find(
      (oldPlanPrice) => oldPlanPrice.id == plano.id,
    ).preco;
    oldPlanosPrice = planos.map((plan) => ({ id: plan.id, preco: plan.preco }));

    if (focar) MPHelper.focusElement(
      event.target.id,
      event.target.parentElement.parentElement.parentElement.id,
    );

    const novaMargem = MPNumeric.recalculateMargin(plano);
    if (novaMargem < MARGEM_MINIMA) {
      plano.status = "lightsalmon";
    }else{
      MPRequests.fetchUpdateProdutosPreco(plano, dias, authenticityToken);
      plano.margem = novaMargem;
      plano.p_d = (plano.preco / dias).toFixed(2);
      plano.status = "#88db88";
      toastComponent.showToastMessage("Preço do plano atualizado com sucesso!");
    };

    planos = MPOrganizer.ordering(planos);
    groupsByRange = MPBuilder.buildRange(planos);
    forceReordering();
  };

  function keyboardShortcuts(event) {
    const functionByKey = {
      "-": () => (dias = MPHelper.changeDays(-1, dias)),
      "+": () => (dias = MPHelper.changeDays(1, dias)),
      "[": () =>
        ([selectedGrupo, valorSelecionado] = MPHelper.changeGroups(
          -1,
          grupos,
          selectedGrupo,
          valorSelecionado,
        )),
      "]": () =>
        ([selectedGrupo, valorSelecionado] = MPHelper.changeGroups(
          1,
          grupos,
          selectedGrupo,
          valorSelecionado,
        )),
    }[event.key];

    return functionByKey && functionByKey() && loadPlans();
  };

  function forceReordering() {
    reordering = !reordering;
  }

  function loading() {
    inLoading = !inLoading;
  }

  onMount(() =>
    (async () => (grupos = [...grupos, ...(await MPRequests.fetchGroups())]))(),
  );
</script>

<div class="card">
  <div class="card-body">
    <MpHeader />
    
    <div class="row g-3 align-items-center">
      <ChangeDays bind:dias {loadPlans} />
      <SelectDestinos bind:selectedGrupo {loadPlans} groups={grupos} />
      <Actions bind:focar bind:grupo_preco />
    </div>

    <Filters
      bind:planos
      bind:groupsByRange
      {allPlans}
      bind:seguradoras
    />
    
    <ToastMessage bind:this={toastComponent} showToast={showMessage}/>
  </div>
</div>

{#if !grupo_preco && planos && planos.length > 0 }
  <div class="card">
    <div class="card-body">
      <div class="container-table">
        <table class="table mt-2">
          <Thead bind:currentFilterType bind:orderStatus bind:planos {reordering} />
          {#each planos as plan, index (index)}
            <TrTable bind:plan={plan} {selectedGrupo} {grupos} {updatePlan} />
          {/each}
        </table>
      </div>
    </div>
  </div>
{:else if grupo_preco && planos && planos.length > 0}
  {#each Object.keys(groupsByRange) as rangeKey, index (index)}
    {#if groupsByRange[rangeKey].plans.length > 0}
      <div class="card">
        <div class="card-body">
          <div class="container-table">
            <h5 class="card-title">
              📦 Grupo: {groupsByRange[rangeKey].name}
            </h5>
            <TableGroup
              bind:groupsByRange
              {reordering}
              {rangeKey}
              {selectedGrupo}
              {grupos}
              {updatePlan}
            />
          </div>
        </div>
      </div>
    {/if}
  {/each}
{/if}

<svelte:window on:keyup={keyboardShortcuts} />

<FloatingButtons {showImproveModal} {planos} {toastComponent} />

{#if modalImproveStatus}
  <AutoImproveModal bind:modalStatus={modalImproveStatus} {selectedGrupo} {grupos} {dias} {planos} {updatePlan}/>
{/if}

{#if inLoading}<Loading />{/if}

<style>
  .container-table { overflow-x: auto; }
</style>
