<script>
  export let showImproveModal, planos;
  export let disabledJobButton = false;
  export let toastComponent = null;
  let y = 0;

  async function startJob(){
    disabledJobButton = true;
    let message = ''
    try {
      const result =  await fetch('/move_preco/ajusta_precos', { method: 'POST' });
      if(result.status !== 200) throw new Error('Erro ao agendar job');
      message = 'Job agendado com sucesso';
    } catch (error) {
      message = 'Erro ao agendar job';
    }
    if(toastComponent) toastComponent.showToastMessage(message);
  }
</script>

<svelte:window bind:scrollY={y} />

<div class="floating-buttons-container">
  <button 
    title="Melhorar preço 1"
    class="floating-button default-hidden"
    class:show={planos && planos.length > 0}
    on:click={() => showImproveModal(1)}
  >
    <i class="fas fa-dollar-sign"></i> 1
  </button>

  <button 
    title="Melhorar preço 2"
    class="floating-button default-hidden"
    class:show={planos && planos.length > 0}
    on:click={() => showImproveModal(2)}
  >
    <i class="fas fa-dollar-sign"></i> 2
  </button>

  <button 
    title="Ajusta preços job"
    class="floating-big-button show"
    class:disabled={disabledJobButton}
    disabled={disabledJobButton}
    on:click={() => startJob()}
  >
    Ajusta preços job
  </button>

  <button 
    title="Voltar ao topo"
    class="floating-button default-hidden" 
    class:show={y > 300} on:click={() => y = 0}
  >
    <i class="fas fa-arrow-up"></i>
  </button>
</div>

<style>
  .floating-buttons-container{ display: flex; align-items: center; justify-content: center; flex-direction: column; gap: .5rem; position: fixed; right: 1rem; bottom: 1rem; z-index: 999999; }
  .floating-button{ background-color: #8c3cc9; color: white; cursor: pointer; border-radius: 50%; transition: all ease .3s; border: none; font-size: 1rem; width: 45px; height: 45px ;padding: 0 .6rem; outline: none; box-shadow: -2px 2px 5px rgba(0,0,0,.5); }
  .floating-button.show:hover{ scale: 1.1; }
  .floating-big-button { background-color: #8c3cc9; color: white; cursor: pointer; border-radius: 3rem; transition: all ease .3s; border: none; font-size: 1rem; width: 100%; height: 45px ;padding: 0 .8rem; outline: none; box-shadow: -2px 2px 5px rgba(0,0,0,.5); }
  .floating-big-button.show:hover{ scale: 1.1; }
  .default-hidden{ display: none; }
  .show{ display: block !important; }
  .disabled{ background-color: #ccc; cursor: not-allowed; }
  .disabled:hover{ scale: 1 !important; }
</style>