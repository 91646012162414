<script>
  import Thead from './Thead.svelte';
  import TrTable from './TrTable.svelte';
  export let groupsByRange, reordering, rangeKey, selectedGrupo, grupos, updatePlan;

  let planos = groupsByRange[rangeKey].plans;
  let orderStatus = groupsByRange[rangeKey].orderStatus;
  let currentFilterType = "preco";
</script>

<table class="table">
  <Thead bind:currentFilterType bind:orderStatus bind:planos bind:groupsByRange {reordering} grupo_preco={true} />
  {#each planos as plan, index (index)}
    <TrTable bind:plan={plan} {selectedGrupo} {grupos} {updatePlan} />
  {/each}
</table>