<script>
  export let statusCovid, callback;
</script>

<div class="col-auto mt-4">
  <label for="covidFilter">Filtrar por Covid:</label>
  <br/>
  <!-- svelte-ignore a11y-no-onchange -->
  <select bind:value={statusCovid} on:change={callback} name="covidFilter" id="covidFilter" class="form-control" >
    <option value="">-- SELECIONAR --</option>
    <option value="true">Com covid</option>
    <option value="false">Sem covid</option>
  </select>
</div>