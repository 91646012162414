import MPNumeric from './MPNumeric';
import MPHelper from './MPHelper';

export default class MPOrganizer {
  static filterByProponent(plans, proponent="Real Seguro Viagem"){
    return plans.filter(plan => plan.proponente == proponent);
  };

  static copyByProponent(plans, proponent="Real Seguro Viagem"){
    return structuredClone(plans.filter(plan => plan.proponente == proponent));
  };

  static filterSimilarPlans(targetPlan, plans){
    return this.ordering(
      plans.filter(plan => {
        return (
          (MPNumeric.formatStringToFloat(plan.assistencia_medica) == MPNumeric.formatStringToFloat(targetPlan.assistencia_medica)) && 
          (targetPlan.seguradora == plan.seguradora) && 
          (plan.proponente != "Real Seguro Viagem")
        );
      })
    ).reverse();
  };

  static isSimilar(planA, planB){
    return (
      (MPNumeric.formatStringToFloat(planA.assistencia_medica) == MPNumeric.formatStringToFloat(planB.assistencia_medica)) && 
      (planA.seguradora == planB.seguradora) && 
      Math.abs(planA.idade_max - planB.idade_max) <= 2
    );
  }

  static isEqual(planA, planB){
    return (
      (MPNumeric.formatStringToFloat(planA.assistencia_medica) == MPNumeric.formatStringToFloat(planB.assistencia_medica)) && 
      (planA.seguradora == planB.seguradora) && 
      (planA.idade_max == planB.idade_max) &&
      (planA.custo_em_reais == planB.custo_em_reais) 
    );
  }

  static isEqualOrBiggest(planA, planB){
    return (
      (MPNumeric.formatStringToFloat(planA.assistencia_medica) >= MPNumeric.formatStringToFloat(planB.assistencia_medica))
    );
  }

  static ordering(plans){
    return plans.sort((planA, planB) => parseFloat(planA.preco) - parseFloat(planB.preco) || planB.idade_max - planA.idade_max);
  };

  static removeDuplicatesPositions(plans){
    //para as posições que tem mais de um produto, deixar apenas com o produto mais barato
    // plan = {position: 1, p_d: "84.66", ...}
    plans = plans.reduce((acc, plan) => { 
      if(acc[plan.position] == undefined) acc[plan.position] = plan;
      else if(acc[plan.position].position == plan.position && (acc[plan.position].preco) > (plan.preco)) acc[plan.position] = plan;
      return acc;
    }, []);
    // Remove null values from array
    return plans.filter(plan => plan != null);
  }

  static genericFilter(plans, filter){
    // Filter plans with or without covid
    if(filter.covid == "true"){
      plans = plans.filter( plan => plan.covid.length > 1);
    }else if(filter.covid == "false"){
      plans = plans.filter( plan => plan.covid.length === 0);
    }

    // Filter plans by maximum age
    if(filter.age) plans = plans.filter( plan => plan.idade_max >= filter.age);

    // Filter plans by insurer
    if(filter.seguradora != "-- SELECIONAR --") plans = plans.filter( plan => plan.seguradora == filter.seguradora);

    // Filters plans by proponent
    plans = plans.filter( plan => filter.empresas[MPHelper.shortProponente(plan.proponente)]);

    // Filter plans by name
    plans = plans.filter(plan => plan.plano.toLowerCase().includes(filter.nomePlan.toLowerCase()));

    return plans;
  };

  static genericSorter(elementA, elementB){
    if(elementA < elementB) return -1;
    if(elementA > elementB) return 1;
    return 0;
  };
};