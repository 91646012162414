<script>
  import ThSorter from './Ths/ThSorter.svelte';
  import MPOrganizer from '../lib/MPOrganizer';
  import MPBuilder from '../lib/MPBuilder';
  import MPNumeric from '../lib/MPNumeric';

  export let planos, currentFilterType = "preco", orderStatus, reordering, groupsByRange = null, grupo_preco = false;

  let accessKeys = {
    preco: "preco", cambio_fornecedor: "cambio_fornecedor", idade: "idade_max", a_med: "assistencia_medica", 
    p_d: "p_d", custo_em_reais: "custo_em_reais", c_reais_d: "c_reais_d", custo_em_dolar: "custo_em_dolar",
    margem: "margem"
  }
  
  function clearOrderStatus(filterBy){
    Object.keys(orderStatus).forEach(key => {if(filterBy != key) orderStatus[key] = undefined});
  };
  
  function sorters(type, change=true, cleaner=null){
    clearOrderStatus(type);
    currentFilterType = type;

    if(change) typeof orderStatus[type] ? orderStatus[type] = !orderStatus[type] : orderStatus[type] = false; 

    const noNeedToOrder = new Set(planos.map(plan => plan[accessKeys[type]])).size == 1;
    if(noNeedToOrder) return;

    let sortedPlanos = [];

    sortedPlanos = planos.sort((planoA, planoB) => {
      const access = accessKeys[type];
      const valueA = planoA[access];
      const valueB = planoB[access];

      return MPOrganizer.genericSorter(MPNumeric.formatStringToFloat(valueA), MPNumeric.formatStringToFloat(valueB));
    });
    
    if(orderStatus[type]) sortedPlanos.reverse();
    if(!grupo_preco && groupsByRange) groupsByRange = MPBuilder.buildRange(sortedPlanos);
    
    planos = [...sortedPlanos];
  };

  $: { 
    reordering; // force reordering
    setTimeout(() => sorters(currentFilterType, false), 100);
  };
</script>

<thead>
  <tr>
    <th>📊</th>
    <th>Seguradora</th>
    <ThSorter status={orderStatus.cambio_fornecedor} sorter={()=> sorters("cambio_fornecedor")} title="Câmbio"/>
    <th> Destino </th>
    <th> Plano </th>
    <th> Data Pesquisa</th>
    <ThSorter status={orderStatus.idade} sorter={()=> sorters("idade")} title="Idade máx"/>
    <ThSorter status={orderStatus.a_med} sorter={()=> sorters("a_med")} title="A. Médica"/>
    <th> Covid </th>
    <ThSorter status={orderStatus.preco} sorter={()=> sorters("preco")} title="Preco (R$)"/>
    <ThSorter status={orderStatus.p_d} sorter={()=> sorters("p_d", (p_d) => parseFloat(p_d))} title="P/D"/>
    <ThSorter status={orderStatus.custo_em_reais} sorter={()=> sorters("custo_em_reais")} title="Custo (R$)"/>
    <ThSorter status={orderStatus.c_reais_d} sorter={()=> sorters("c_reais_d")} title="C (R$)/D"/>
    <ThSorter status={orderStatus.custo_em_dolar} sorter={()=> sorters("custo_em_dolar")} title="Custo (USD)"/>
    <ThSorter status={orderStatus.c_dolar_d} sorter={()=> sorters("c_dolar_d")} title="C (USD)/D"/>
    <ThSorter status={orderStatus.margem} sorter={()=> sorters("margem")} title="Margem"/>
  </tr>
</thead>

<style>
  th{ text-align: center; vertical-align: middle; border: 1px solid rgb(214, 207, 207); padding: .3rem 1rem !important; position: relative; }
</style>