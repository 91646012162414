export class OrderStatus {
  constructor() {
    this.idade = undefined;
    this.custo_em_reais = undefined;
    this.custo_em_dolar = undefined;
    this.cambio_fornecedor = undefined;
    this.preco = false;
    this.p_d = undefined;
    this.c_reais_d = undefined;
    this.c_dolar_d = undefined;
    this.margem = undefined;
    this.a_med = undefined;
  }
}