export default class MPRequests {
  static DOMAIN = "https://ww2.assistentedeviagem.site";
  static AUTHORIZATION = 'Basic ' + btoa(`${process.env.HTTP_BASIC_AUTH_USERNAME}:${process.env.HTTP_BASIC_AUTH_PASSWORD}`);

  static async fetchGroups(){
    const rawGroups = await fetch(`${this.DOMAIN}/api/v1/grupos`, {
      method: 'GET',
      headers: {  'Authorization': this.AUTHORIZATION }
    });

    const groups = await rawGroups.json();
    
    return groups;
  };
  
  static async fetchRealPlans(selectedGrupo, days){
    const url = `${this.DOMAIN}/api/v1/painel?dias=${days}`;
    const concatUrl = selectedGrupo !== 0 ? `&grupo=${selectedGrupo}` : "";
    const rawResponse = await fetch(url+concatUrl, {
      method: 'GET',
      headers: { 'Authorization': this.AUTHORIZATION }
    }); 

    const plans = await rawResponse.json();

    return plans;
  };
  
  static async fetchPricePlans(destiny, days){
    const rawResponse = await fetch(`/move_preco/consulta?destino=${destiny}&dias=${days}`);
    const plans = await rawResponse.json();
    
    return plans;
  };

  static async fetchProponentWithoutPlans(type, days, destiny){
    const rawResponse = await fetch(`/move_preco/proponente_sem_plano?dias=${days}&destino=${destiny}&tipo=${type}`);
    const proponent = await rawResponse.json();
  
    return proponent;
  }
  
  static async fetchCambioFornecedor(insuranceCompany){
    const rawResponse = await fetch(`/move_preco/cambio_fornecedor?fornecedor=${insuranceCompany}`);
    const exchange = await rawResponse.json();
  
    return exchange;
  };
  
  static async fetchUpdateProdutosPreco(plan, days, authenticityToken){
    const regExp = new RegExp(/^\d{1,}(\.\d{0,})?$/); // => Identify the row within plans;
    
    if(!regExp.test(plan.preco.toString())) return false;
  
    const rawResponse = await fetch(`/produtos_precos/${plan.id}.js`, {
      method:'PUT',
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "authenticity_token": authenticityToken
      },
      body: JSON.stringify({ 
        id: plan.id, 
        update_move: true,
        produtos_preco: { 
          produto_id: plan.prod_id,
          quantidade: days, 
          custeio_id: plan.custeio,
          preco: plan.preco,
          moeda: plan.moeda
        }
      }), 
    });
  
    return rawResponse;
  };
};