<script>
  export let showToast = false;
  let message = '';

  export function showToastMessage(messageText) {
    message = messageText;
    showToast = true;

    setTimeout(() => {
      showToast = false;
    }, 6000);
  }
</script>

{#if showToast}
  <div class="toastMessage">
    <div>{message}</div>
  </div>
{/if}

<style>
  .toastMessage {
    font-weight: bold;
    position: fixed;
    top: 5%;
    z-index: 999;
    left: 50%;
    background-color: #d0ebf0;
    color: #145460;
    padding: 10px 20px;
    border-radius: 5px;
    transform: translate(-50%, -50%);
  }
</style>