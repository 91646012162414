<script>
  export let selectedGrupo, loadPlans, groups;
</script>

<div class="col-auto">
  <label for="destinationSelector">
    Destino:
  </label>
  <!-- svelte-ignore a11y-no-onchange -->
  <select bind:value={selectedGrupo} name="destinationSelector" id="destinationSelector" on:change={loadPlans} class="form-control" >
    {#each groups as group}
      <option value={group[0]}>
        {group[1]}
      </option>
    {/each}
  </select>
</div>