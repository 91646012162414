export default class MPHelper {
  static DESTINATIONS = {
    26: 'europe', 27: 'world', 28: 'brasil',
    29: 'latin-america',  30: 'united-states', 34: 'receptive'
  };

  static PROPONENTS = {
    'Real Seguro Viagem': { shortName: 'RSV', color: "#fef0ff" },
    'Seguros Promo': { shortName: 'SP', color: "#D1F8CD" },
    'Assistente de Viagem': { shortName: 'AV', color: "#ADB0FF" }
  };

  static shortProponente(proponente) {
    return this.PROPONENTS[proponente]?.shortName || proponente;
  };

  static groupForDestination(valor) {
    return this.DESTINATIONS[valor];
  };

  static colorRow(company) {
    return this.PROPONENTS[company]?.color || "#fff";
  };

  static focusElement(targetElementID, targetParentElementId) {
    setTimeout(() => {
      const targetElement = document.getElementById(targetElementID);
      const targetParentElement = document.getElementById(targetParentElementId);

      if (!targetElement || !targetParentElement) return;

      targetElement.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
      targetParentElement.classList.add("focus");
      targetElement.focus();

      setTimeout(() => targetParentElement.classList.remove("focus"), 1500);
    }, 250);
  };

  static changeDays(number, days) {
    const newNumberOfDays = days + number;

    if (newNumberOfDays > 0 && newNumberOfDays <= 365) return newNumberOfDays;

    return days;
  };

  static changeGroups(number, grupos, selectedGrupo, valorSelecionado) {
    valorSelecionado += number;
    selectedGrupo = grupos[Math.abs(valorSelecionado) % grupos.length][0];

    return [ selectedGrupo, valorSelecionado ];
  };
};